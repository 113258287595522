<template>
  <div />
</template>

<script>
export default {
  created() {
    window.open('/reports', '_blank');
    this.$router.go(-1)
  }
}
</script>

<style scoped>

</style>